import React from 'react';

function App() {
  return (
    <>
      <div className="background-video absolute--fill absolute aspect-ratio aspect-ratio--4x3 o-80">
        <video className="aspect-ratio--object" autoPlay loop muted>
          <source src={`${process.env.PUBLIC_URL}/video/044576845-ground-crew-playing-harmonicas_prores.mp4`} type="video/mp4" />
        </video>
      </div>

      <div className="content vh-100 flex flex-column items-center tc justify-center">
        <h1 className="f2 ttu tracked ma0 mb2">Oldies But Goodies</h1>
        <h2 className="f4 fw4 ma0 mv2">Our exciting new website is coming soon! Check back later...</h2>

        <audio src={`${process.env.PUBLIC_URL}/audio/background.mp3`} controls autoPlay loop style={{opacity: 0.01}} />
        <iframe title="silencehack" src={`${process.env.PUBLIC_URL}/audio/silence.mp3`} allow="autoplay" id="audio" style={{display:'none'}}></iframe>
      </div>
    </>
  );
}

export default App;
